<template>
  <div id="data-view">
    <dv-full-screen-container>
      <div class="main-header">
        <div class="mh-left">
          <dv-border-box-2 style="
              width: 160px;
              height: 50px;
              line-height: 50px;
              text-align: center;
              margin-left: 130px;
              color: #fff;
            ">
            <left-date-time />
          </dv-border-box-2>
        </div>
        <div class="mh-middle">
          <img src="@/assets/logo.png" style="width: 40px; height: 40px; margin-bottom: 5px" />
          公物仓管理系统
        </div>
        <div class="mh-right">
          <dv-border-box-2 style="
              width: 120px;
              height: 50px;
              line-height: 50px;
              text-align: center;
              margin-left: 200px;
            ">
            <a href="/" style="color: #fff">综合管理台</a>
          </dv-border-box-2>
        </div>
      </div>

      <dv-border-box-1 class="main-container">
        <dv-border-box-3 class="left-chart-container">
          <Left-Chart-1 ref="leftDv" v-model:warehouseList="warehouseList" />
        </dv-border-box-3>

        <div class="right-main-container">
          <div class="rmc-top-container">
            <dv-border-box-3 class="rmctc-left-container">
              <Center-Map ref="centerDv" v-model:warehouseList="warehouseList" @updateRight="updateRight" />
            </dv-border-box-3>

            <div class="rmctc-right-container">
              <dv-border-box-3 class="rmctc-chart-1">
                <Right-Chart-1 ref="rightChat1" />
                <Right-Chart-3 ref="rightChat3" />
              </dv-border-box-3>

              <dv-border-box-4 class="rmctc-chart-2" :reverse="true">
                <Right-Chart-2 ref="rightChat2" />
              </dv-border-box-4>
            </div>
          </div>
        </div>
      </dv-border-box-1>
    </dv-full-screen-container>
  </div>
</template>

<script>
import * as assetsApi from '@/api/ams/assets'
import LeftDateTime from './LeftDateTime.vue'
import LeftChart1 from './LeftChart1'

import CenterMap from './CenterMap'

import RightChart1 from './RightChart1'
import RightChart2 from './RightChart2'
import RightChart3 from './RightChart3'

export default {
  name: 'DataView',
  components: {
    LeftDateTime,
    LeftChart1,
    CenterMap,
    RightChart1,
    RightChart2,
    RightChart3
  },
  data() {
    return {
      warehouseList: null
    }
  },
  mounted() {
    this.init()
  },
  created() { },
  methods: {
    init() {
      assetsApi
        .unitAssetsReport()
        .then((res) => {
          if (res.code === 0) {
            this.warehouseList = res.data.reverse()
            this.warehouseList.forEach((item) => {
              if (item.longitude2end) {
                item.group = 'xiamen'
              } else if (item.code === '255014009') {
                item.group = 'zhangzhuo'
              }
              item.index = item.id
              item.value = item.count
              item.show = true
            })
          } else {
            this.$message.error(res.msg)
          }

          setTimeout(this.init, 120 * 1000)
        })
        .catch((e) => {
          setTimeout(this.init, 120 * 1000)
          this.$message.error(e.message)
        })
    },
    refreshData() {
      this.init()
    },
    updateRight(data) {
      this.$refs.rightChat1.setConfig(data)
      this.$refs.rightChat3.setConfig(data)
    }
  }
}
</script>

<style lang="less">
#data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  #dv-full-screen-container {
    background-image: url('./img/bg.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }

  .main-header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .mh-left {
      font-size: 20px;
      color: rgb(1, 134, 187);

      a:visited {
        color: rgb(1, 134, 187);
      }
    }

    .mh-middle {
      font-size: 40px;
    }

    .mh-left,
    .mh-right {
      width: 450px;
    }
  }

  .main-container {
    height: calc(~'100% - 80px');

    .border-box-content {
      padding: 20px;
      box-sizing: border-box;
      display: flex;
    }
  }

  .left-chart-container {
    width: 22%;
    padding: 10px;
    box-sizing: border-box;

    .border-box-content {
      flex-direction: column;
    }
  }

  .right-main-container {
    width: 78%;
    padding-left: 5px;
    box-sizing: border-box;
  }

  .rmc-top-container {
    height: 100%;
    display: flex;
  }

  .rmctc-left-container {
    width: 100%;
  }

  .rmctc-right-container {
    width: 40%;
  }

  .rmc-bottom-container {
    height: 35%;
  }

  .rmctc-chart-1 {
    width: 100%;
    height: 66%;

    .border-box-content {
      flex-direction: column;
    }
  }

  .rmctc-chart-2 {
    height: 34%;
  }
}
</style>
